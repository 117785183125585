import React from "react";
import PropTypes from "prop-types";
import { Menu } from "antd";

//components
import DrawerWrapper from "../../shared/DrawerWrapper";

//helpers
import useQueryParams from "../../../hooks/useQueryParams";


function SortByDrawer({ sortOptions, onClose, isOpened }) {
  const [, setQueryParams] = useQueryParams();

  const handleItemClick = value => {
    setQueryParams({ filter: value, page: 1 });

    if (onClose) {
      onClose();
    }
  }


  return (
    <DrawerWrapper
      closeDrawer={onClose}
      isDrawerOpened={isOpened}
      className={"mobile-filter-drawer"}
    >
      <div className="mobile-filter-drawer__header">
        <span>Sorting</span>
      </div>
      <div className="mobile-filter-drawer__body">
        <Menu>
          {
            sortOptions.map(({ label, value }, index) => (
              <Menu.Item
                className="mobile-filter-drawer__link"
                key={`${label}-${index}`}
                onClick={() => handleItemClick(value)}
                data-testid={value}
              >
                <span className="mobile-filter-drawer__link-label">{ label }</span>
              </Menu.Item>
            ))
          }
        </Menu>
      </div>
    </DrawerWrapper>
  );
}

SortByDrawer.propTypes = {
  isOpened: PropTypes.bool,
  sortOptions: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};


export default SortByDrawer;
